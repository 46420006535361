import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="offer-review"
export default class extends Controller {
  static targets = ["reviewForm", "saveOnlyButton", "submitButton", "altLendersLink"];
  
  connect() { }

  activeClassList = ["active", "bg-blue-100", "text-white-500", "dark:border-blue-500", "dark:text-white-500"]

  handleRemoveFromGrossDepositClick(event) {
    this.replaceTotalCashflow(this.recalculateCashflow)
  }

  handleDeductFromGrossDepositClick(event) {
    this.replaceTotalCashflow(this.recalculateCashflow)
  }

  handleFrequencyChange(event) {
    let secondPositionInput = document.getElementById(`cashflow_review_payload_${event.target.dataset.id}_second_position`)
    if ( secondPositionInput.checked && event.target.value === '') {
      event.target.style.border = "1px solid red";
    } else {
      event.target.style.border = "";
    }

    this.validateAllFrequencyDropdowns();
  }

  validateSecondPositionClick(event) {
    let secondPositionFrequencyInput = document.getElementById(`cashflow_review_payload_${event.target.dataset.id}_frequency`)
    if (event.target.checked === true && secondPositionFrequencyInput.value === "") {
      secondPositionFrequencyInput.style.border = "1px solid red";
    } else {
      secondPositionFrequencyInput.style.border = "";
    }

    this.validateAllFrequencyDropdowns();
  }

  validateAllFrequencyDropdowns() {
    if(this.allFrequencyDropdownEls().some(this.isEmpty)) {
      this.submitButton.classList.remove('bg-blue-500')
      this.submitButton.classList.remove('btn-primary')
      this.submitButton.classList.add('bg-gray-300')
      this.submitButton.disabled = true
    } else {
      this.submitButton.classList.add('bg-blue-500')
      this.submitButton.classList.add('btn-primary')
      this.submitButton.classList.remove('bg-gray-300')
      this.submitButton.disabled = false
    }
  }

  allFrequencyDropdownEls() {
    return Array.from(
      document.querySelectorAll('[id^="cashflow_review_payload_"][id$="_frequency"]'))
  }

  isEmpty(element, index, array) {
    let secondPositionInput = document.getElementById(`cashflow_review_payload_${element.dataset.id}_second_position`)
    return element.value === '' && secondPositionInput?.checked;
  }
  
  toggleNoTransactionsMessage() {
    this.recalculateCashflow === "$0.00" ?
      this.notFoundEl.classList.remove('hidden') : 
      this.notFoundEl.classList.add('hidden') 
  }

  replaceTotalCashflow(amount) {
    document.querySelector('.cashflow-review-total').querySelector('#amount').innerText = amount
  }

  
  currency(number, options) {
    if (number === null || number === undefined) return number;

    const defaultOptions = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      };

    if (options) {
      options = { ...defaultOptions, ...options };
    } else {
      options = defaultOptions;
    }
    return new Intl.NumberFormat('en-US', options).format(number);
  }

  get recalculateCashflow() {
    let total = 0;
    Array.from(this.activeFormDropTarget.children).map(element => {
      let gross_deposit_check = element.querySelector("input[type='checkbox'][data-checkType='deduct_from_gross_deposit']")
      if(!!gross_deposit_check && gross_deposit_check?.value === "true" && gross_deposit_check?.checked) {
        total += parseFloat(element.querySelector('.amount')?.dataset?.amountRaw || "0")
      }
    });

    return this.currency(total, { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }
  get altLendersTabLink() {
    return document.getElementById("altLendersLink")
  }
  
  get altLendersCashflowEl() {
    return document.getElementById("alt_lenders_cashflow")
  }
  
  get activeFormDropTarget() {
    return document.querySelector(".form-drop-target")
  }
  
  get notFoundEl() {
    return this.activeFormDropTarget.querySelector('#notfound')
  }

  get submitButton() {
    return document.querySelector('.edit_cash_flow_request').querySelector('button[type="submit"]');
  }

}
