import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="offer-review"
export default class extends Controller {
  static targets = ["reviewForm", "saveOnlyButton", "submitButton",
                    "depositsLink", "withdrawalsLink"];
  
  connect() { }

  activeClassList = ["active", "bg-blue-100", "text-white-500", "dark:border-blue-500", "dark:text-white-500"]

  deposits(event) {
    event.preventDefault();
    this.withdrawalsTabLink.classList.remove(...this.activeClassList);
    this.withdrawalsCashflowEl.classList.add("hidden");
    this.depositsCashflowEl.classList.remove("hidden");
    this.depositsLinkTarget.classList.add(...this.activeClassList);
  }

  withdrawals(event) {
    event.preventDefault();
    this.depositsTabLink.classList.remove(...this.activeClassList);
    this.withdrawalsCashflowEl.classList.remove("hidden");
    this.depositsCashflowEl.classList.add("hidden");
    this.withdrawalsLinkTarget.classList.add(...this.activeClassList);
  }

  handleRemoveFromGrossDepositClick(event) {
    event.target.dataset.action = "change->offer-review#handleDeductFromGrossDepositClick"
    const row = event.target.parentElement.parentElement
    const tabView = row.id.split('-')[0]
    this.getCashflowTab(tabView).querySelector('tbody').appendChild(event.target.parentElement.parentElement)

    this.removeNotesFromRow(row)
    this.replaceTotalCashflow(this.recalculateCashflow)
    this.toggleNoTransactionsMessage();
  }

  handleDeductFromGrossDepositClick(event) {
    event.target.dataset.action = "change->offer-review#handleRemoveFromGrossDepositClick"
    const row = event.target.parentElement.parentElement
    this.activeFormDropTarget.appendChild(row)

    this.addNotesToRow(row)
    this.replaceTotalCashflow(this.recalculateCashflow)
    this.toggleNoTransactionsMessage();
  }

  toggleNoTransactionsMessage() {
    this.recalculateCashflow === "$0.00" ?
      this.notFoundEl.classList.remove('hidden') : 
      this.notFoundEl.classList.add('hidden') 
  }

  removeNotesFromRow(row) {
    const notes = row.querySelector('.notes')
    if(notes) {
      row.removeChild(notes)
    }
  }

  addNotesToRow(row) {
    const notes = document.createElement('td')
    notes.classList.add('px-6', 'py-4', 'notes');
      notes.innerHTML = `
      <input type="text" name="cashflow_review_payload[][notes]" class="form-input w-full h-10">
    `
    row.appendChild(notes)
  }

  replaceTotalCashflow(amount) {
    document.querySelector('.cashflow-review-total').querySelector('#amount').innerText = amount
  }

  getCashflowTab(tab) {
    if(tab === "deposits"){
      return this.depositsCashflowEl
    } else {
      return this.withdrawalsCashflowEl 
    }
  }
  
  currency(number, options) {
    if (number === null || number === undefined) return number;

    const defaultOptions = {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      };

    if (options) {
      options = { ...defaultOptions, ...options };
    } else {
      options = defaultOptions;
    }
    return new Intl.NumberFormat('en-US', options).format(number);
  }

  get recalculateCashflow() {
    let total = 0;
    Array.from(this.activeFormDropTarget.children).map(element => {
      total += parseFloat(element.querySelector('.amount')?.dataset?.amountRaw || "0")
    });

    return this.currency(total, { style: 'currency', currency: 'USD', maximumFractionDigits: 2, minimumFractionDigits: 2 });
  }

  get depositsTabLink() {
    return document.getElementById("depositsLink")
  }

  get withdrawalsTabLink() {
    return document.getElementById("withdrawalsLink")
  }

  get activeFormDropTarget() {
    return document.querySelector(".review-form-drop-target")
  }

  get withdrawalsCashflowEl() {
    return document.getElementById("withdrawals_cashflow")
  }
  
  get notFoundEl() {
    return this.activeFormDropTarget.querySelector('#notfound')
  }

  get depositsCashflowEl() {
    return document.getElementById("deposits_cashflow")
  }
}
